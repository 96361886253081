import { createContext, useContext } from 'react';
import { getAllPois } from '../use-cases';
import { createPoiStore } from './createPoiStore';
import { createPoiSearchStore } from './createPoiSearchStore';
import { createWaitTimesStore } from './createWaitTimesStore';
import { getSkydiveClient } from '../../../skydive';

const poiStore = createPoiStore(getAllPois);
const poiSearchStore = createPoiSearchStore(poiStore);
const waitTimesStore = createWaitTimesStore(getSkydiveClient());

const PoiStoreContext = createContext(poiStore);
const PoiSearchStoreContext = createContext(poiSearchStore);
const WaitTimesContext = createContext(waitTimesStore);

export const usePoiStore = () => useContext(PoiStoreContext);
export const usePoiSearchStore = () => useContext(PoiSearchStoreContext);
export const useWaitTimesStore = () => useContext(WaitTimesContext);
