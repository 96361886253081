import styled from '@emotion/styled';
import {
	ChevronRightIcon,
	ITheme,
	StyledIconLink,
	StyledLinkText,
	UnstyledLi,
	UnstyledUl,
	themed,
} from '@m2/blueprint-ui';
import React from 'react';
import { Color } from '../../../../../../styles/Color';
import { IMenuItemNavBar } from '../types/IMenuItemNavBar';
import { useThrottledState } from '../../../../../../utils/useThrottledState';
import { MENU_TRANSITION_DELAY } from './constants';

interface IDropDownItemProps {
	subMenus: IMenuItemNavBar[];
	isScrolledDown: boolean;
	isTransparent: boolean;
}

export const DropDownMenuItem = ({
	subMenus,
	isScrolledDown,
	isTransparent,
}: IDropDownItemProps) => {
	const [visibleIndex, setVisibleIndex] = useThrottledState<number | undefined>(
		MENU_TRANSITION_DELAY,
		undefined,
	);

	if (subMenus.length === 0) {
		return null;
	}

	return (
		<>
			{subMenus.map((subMenu: IMenuItemNavBar, index: number) => (
				<StyledMenuContainer
					key={subMenu.id}
					onFocus={() => setVisibleIndex(index)}
					onBlur={() => setVisibleIndex(undefined)}
					onMouseEnter={() => setVisibleIndex(index)}
					onMouseLeave={() => setVisibleIndex(undefined)}
				>
					<StyledCustomClickable href={subMenu.url}>
						<StyledCustomLinkText>{subMenu.title}</StyledCustomLinkText>
					</StyledCustomClickable>

					{subMenu.subMenus.length > 0 && (
						<>
							<ChevronRightIcon variation="brand" />
							<StyledSubMenu isVisible={index === visibleIndex}>
								<DropDownMenuItem
									subMenus={subMenu.subMenus}
									isTransparent={isTransparent}
									isScrolledDown={isScrolledDown}
								/>
							</StyledSubMenu>
						</>
					)}
				</StyledMenuContainer>
			))}
		</>
	);
};

const StyledCustomLinkText = styled.div<{}, ITheme>`
	color: ${themed(({ color }) => color.text.default)};

	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const StyledSubMenu = styled(UnstyledUl)<{ isVisible: boolean }>`
	position: absolute;
	top: 0;
	left: 255px;
	flex-direction: column;
	width: 263px;
	height: auto;
	border-radius: 4px;
	background-color: white;
	padding: 8px 0;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
	display: flex;
	transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
	transform: translateY(${({ isVisible }) => (isVisible ? '0' : '10px')});
	z-index: 1;
	margin-top: -8px;
`;

const StyledMenuContainer = styled(UnstyledLi)<{}, ITheme>`
	height: 50px;
	display: flex;
	align-items: center;
	${StyledLinkText} {
		color: ${themed(({ color }) => color.text.default)};
	}

	padding: 0 10px;
	position: relative;

	:hover {
		background: ${Color.PrimaryDarkGray20};
	}
`;

const StyledCustomClickable = styled(StyledIconLink)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	max-width: calc(100% - 24px);

	:after {
		display: none;
	}
`;
