import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';
import { TypeFilterField } from '../domain/types/TypeFilterField';
import { createUnifiedSearchNothingFoundViewModel } from './UnifiedSearchNothingFoundViewModel';
import { createSearchResultItemViewModel } from './SearchResultItemViewModel';
import { PoiStore } from '../../shop-dine-services/domain/stores/createPoiStore';
import { MINIMAL_CHARS_FOR_RESULTS } from '../constants';
import { createUnifiedSearchEmptyStateViewModel } from './UnifiedSearchEmptyStateViewModel';
import { ITranslationFunction } from '../../i18n/translate/useTranslation';

export type SearchOverlayViewModel = ReturnType<
	typeof createSearchOverlayViewModel
>;

export interface IUnifiedSearchOverlayViewModelProps {
	poiStore: PoiStore;
	unifiedSearchStore: UnifiedSearchStore;
	pathToPageIdMap: IPathToPageIdMap;
	t: ITranslationFunction;
}

export const createSearchOverlayViewModel = ({
	poiStore,
	unifiedSearchStore,
	pathToPageIdMap,
	t,
}: IUnifiedSearchOverlayViewModelProps) => {
	const resultItemViewModels = unifiedSearchStore?.results
		.map((item) =>
			createSearchResultItemViewModel({
				item,
				unifiedSearchStore,
				pathToPageIdMap,
			}),
		)
		.filter(
			({ url, contentType }) =>
				!(!url && contentType === TypeFilterField.Content),
		);

	const hasMinimalCharsForResult =
		unifiedSearchStore.userQuery.length >= MINIMAL_CHARS_FOR_RESULTS;

	const isSearchingWithoutResults =
		hasMinimalCharsForResult && resultItemViewModels.length === 0;

	const nothingFoundViewModel = isSearchingWithoutResults
		? createUnifiedSearchNothingFoundViewModel()
		: null;

	const emptyStateViewModel = !hasMinimalCharsForResult
		? createUnifiedSearchEmptyStateViewModel()
		: null;

	const recentSearchViewModels = unifiedSearchStore?.recentSearches.map(
		(item) =>
			createSearchResultItemViewModel({
				item,
				unifiedSearchStore,
				pathToPageIdMap,
			}),
	);

	const showRecentSearches =
		resultItemViewModels.length === 0 &&
		unifiedSearchStore.userQuery.length < MINIMAL_CHARS_FOR_RESULTS;

	const removeRecentSearch = (key: string) => {
		unifiedSearchStore.removeRecentSearch(key);
	};

	return {
		onMount() {
			unifiedSearchStore.init();

			if (!poiStore.value) {
				poiStore.load();
			}
		},
		onUnmount() {
			// No-op: currently, nothing to clean up
		},
		onSearch(value: string) {
			unifiedSearchStore?.setSuggestionInput(value);
		},
		handleClearInput() {
			unifiedSearchStore?.reset();
		},
		get showFilters() {
			return this.hasUserQuery && hasMinimalCharsForResult;
		},
		get hasUserQuery() {
			return unifiedSearchStore?.userQuery.length > 0;
		},
		get userQuery() {
			return unifiedSearchStore?.userQuery;
		},
		changeUserQuery(value: string) {
			unifiedSearchStore.setUserQuery(value);
		},
		resultItemViewModels,
		recentSearchViewModels,
		placeholderText: t('shop_dine_services_search_query_placeholder'),
		get isUnifiedSearchActive() {
			return unifiedSearchStore?.isActive;
		},
		searchResultsCountText: t('unified_search_count_web', {
			count: resultItemViewModels.length,
		}),
		recentSearchesText: t('recent_unified_search_header'),
		showRecentSearches,
		removeRecentSearch,
		get filterButtons() {
			const { selectedFilters } = unifiedSearchStore;

			return [
				{
					label: 'Flights',
					type: TypeFilterField.Flights,
					isActive: selectedFilters.includes(TypeFilterField.Flights),
				},
				{
					label: 'Facilities',
					type: TypeFilterField.Pois,
					isActive: selectedFilters.includes(TypeFilterField.Pois),
				},
				{
					label: 'Information',
					type: TypeFilterField.Content,
					isActive: selectedFilters.includes(TypeFilterField.Content),
				},
			];
		},
		onSelectFilter(filter: TypeFilterField) {
			const filterIndex = unifiedSearchStore?.selectedFilters.indexOf(filter);
			const selectedFilters = [...unifiedSearchStore?.selectedFilters];

			if (filterIndex > -1) {
				selectedFilters.splice(filterIndex, 1);
			} else {
				selectedFilters.push(filter);
			}

			unifiedSearchStore.setSelectedFilters(selectedFilters);
		},
		nothingFoundViewModel,
		emptyStateViewModel,
		showEndOfResults: !!(!showRecentSearches && resultItemViewModels?.length),
		endOfResultText: t('unified_search_end_of_results'),
	};
};
