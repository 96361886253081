import React from 'react';
import styled from '@emotion/styled';
import { GridItem, Grid, Responsive, StyledIconButton } from '@m2/blueprint-ui';
import { FlightAutoSuggest } from '../../../flights/search/ui/FlightAutoSuggest';
import { ISearchBarViewModel } from './SearchBarViewModel';
import { SearchButton } from './SearchButton';

interface ISearchBarProps {
	viewModel: ISearchBarViewModel;
}

export const SearchBar = ({
	viewModel: { buttons, autoSuggest },
}: ISearchBarProps) => (
	<SearchBarWrapper>
		<SearchBarRowWrapper>
			<StyledButtonWrap>
				{buttons.map((button) => (
					<SearchButton key={button.title} viewModel={button} />
				))}
			</StyledButtonWrap>
		</SearchBarRowWrapper>
		<StyledFlightSearchMain>
			<GridItem columns={12}>
				<FlightAutoSuggest viewModel={autoSuggest} />
			</GridItem>
		</StyledFlightSearchMain>
	</SearchBarWrapper>
);

export const StyledFlightSearchMain = styled(Grid)`
	${StyledIconButton} {
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: 0px !important;

		:hover {
			border: 0;
		}
	}
`;

export const SearchBarWrapper = styled.div`
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
	padding: 40px 40px 32px 40px;
	border-radius: 8px;
	width: 100%;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: 24px;
	}
`;

export const SearchBarRowWrapper = styled.div`
	display: flex;
`;

export const StyledButtonWrap = styled.div`
	display: flex;
	margin-bottom: 31px;
	overflow: hidden;

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		margin-bottom: 26px;

		a:last-of-type {
			overflow: hidden;
			div {
				display: block;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
`;
