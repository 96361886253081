import styled from '@emotion/styled';
import { CheckIcon, IUIComponentProps } from '@m2/blueprint-ui';
import React, { PropsWithChildren } from 'react';

export const ParagraphWithCheck = ({
	children,
	className,
}: PropsWithChildren<IUIComponentProps>) => (
	<StyledParagraphWithCheck className={className}>
		<StyledCheckIcon>
			<CheckIcon />
		</StyledCheckIcon>
		{children}
	</StyledParagraphWithCheck>
);

export const StyledParagraphWithCheck = styled.div`
	transition: color 0.25s ease;
	color: var(--paragraph-color, #a7a7a7);
	display: flex;
	align-items: flex-start;
	gap: 8px;
	margin-block-end: var(--paragraph-margin, 12px);
	text-align: start;
`;

export const StyledCheckIcon = styled.div`
	color: var(--paragraph-icon-color, inherit);
	svg path {
		fill: currentColor;
	}
`;
