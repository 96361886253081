import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { ISearchButtonViewModel } from './SearchButtonViewModel';
import { Color } from '../../../styles/Color';
import { RouterLink } from '../../common/link/RouterLink';

interface ISearchButtonProps {
	viewModel: ISearchButtonViewModel;
}

export const SearchButton = ({
	viewModel: { link, title, active },
}: ISearchButtonProps) => {
	const button = <StyledButton active={active}>{title}</StyledButton>;

	if (!link) return button;

	return <RouterLink href={link}>{button}</RouterLink>;
};

interface IStyledProps {
	active: boolean;
}

const StyledButton = styled.div<IStyledProps>`
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	margin-right: 80px;
	font-size: 24px;
	box-sizing: border-box;
	border-bottom: ${({ active }) =>
		active
			? `solid 2px ${Color.PrimaryOrange100}`
			: `solid 2px ${Color.PrimaryWhite}`};
	color: ${Color.PrimaryDarkGray100};
	line-height: normal;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 18px;
		margin-right: 24px;
	}
`;
