import {
	createFlightAutoSuggestViewModel,
	IFlightAutoSuggestViewModel,
} from '../../../flights/search/ui/FlightAutoSuggestViewModel';
import { Url } from '../../../navigation';
import { ISearchButtonViewModel } from './SearchButtonViewModel';
import { CustomPageId } from '../../../contentful/routes';
import { FlightSuggestionStore } from '../../../flights/shared/domain/stores/createFlightSuggestionStore';
import { GetFlightDetailUrl } from '../../../flights/search/domain/use-cases/getFlightDetailUrl';

export interface ISearchBarViewModel {
	buttons: ISearchButtonViewModel[];
	autoSuggest: IFlightAutoSuggestViewModel;
}

export interface ISearchBarViewModelProps {
	flightSuggestionStore: FlightSuggestionStore;
	navigate: (url: Url) => void;
	getFlightDetailUrl: GetFlightDetailUrl;
}

export const createSearchBarViewModel = ({
	flightSuggestionStore,
	navigate,
	getFlightDetailUrl,
}: ISearchBarViewModelProps): ISearchBarViewModel => ({
	buttons: [
		{
			title: 'Flights',
			active: true,
		},
		{
			title: 'Parking',
			active: false,
			link: `/${CustomPageId.Park}`,
		},
		{
			title: 'Shop/Dine/Services',
			active: false,
			link: `/shop-dine-services`,
		},
	],
	autoSuggest: createFlightAutoSuggestViewModel({
		flightSuggestionStore,
		homepageWidget: true,
		navigate,
		getFlightDetailUrl,
	}),
});
