import { IDropdownItem } from '@m2/blueprint-ui';

import * as querystring from 'querystring';
import { KeyboardEvent } from 'react';

import { Url } from '../../../navigation';

import { FlightSuggestionStore } from '../../shared/domain/stores/createFlightSuggestionStore';
import { GetFlightDetailUrl } from '../domain/use-cases/getFlightDetailUrl';
import { maybeNavigateToFlight } from '../domain/use-cases/maybeNavigateToFlight';

export interface IFlightAutoSuggestViewModel {
	isLoading: boolean;
	suggestionList: IDropdownItem[];
	onInputValueChange: (input: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelectItem: (event: any) => void;
	onClickSearch: () => void;
	onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
	readonly input: string;
	onClear: () => void;
}

export interface IFlightAutoSuggestViewModelProps {
	homepageWidget?: boolean;
	navigate: (url: Url) => void;
	flightSuggestionStore: FlightSuggestionStore;
	getFlightDetailUrl: GetFlightDetailUrl;
}

export const createFlightAutoSuggestViewModel = ({
	navigate,
	flightSuggestionStore,
	getFlightDetailUrl,
}: IFlightAutoSuggestViewModelProps): IFlightAutoSuggestViewModel => ({
	get suggestionList() {
		return flightSuggestionStore.suggestionList ?? [];
	},
	get isLoading() {
		return flightSuggestionStore.isLoading;
	},
	get input() {
		return flightSuggestionStore.input ?? '';
	},
	onInputValueChange: (input) => {
		flightSuggestionStore?.setSuggestionInput(input);
	},
	onSelectItem: (event) => {
		if (!event) {
			flightSuggestionStore?.setSuggestionInput('');
			return;
		}

		const query: querystring.ParsedUrlQueryInput = {
			[event.filter]: event.stringValue,
		};

		if (event.filter === 'mainFlightNumber') {
			const url = getFlightDetailUrl(event.stringValue);
			if (url) {
				navigate(url);
				return;
			}
		}

		flightSuggestionStore.applySearch({
			[event.filter]: event.stringValue,
		});
		maybeNavigateToFlight(query, navigate);
	},
	onClickSearch: () => {
		flightSuggestionStore.applySearch({
			all: flightSuggestionStore.input ?? '',
		});
		maybeNavigateToFlight({ userQuery: flightSuggestionStore.input }, navigate);
	},
	onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key !== 'Enter') return;
		flightSuggestionStore.applySearch({
			all: flightSuggestionStore.input ?? '',
		});
		maybeNavigateToFlight({ userQuery: flightSuggestionStore.input }, navigate);
	},
	onClear: () => {
		flightSuggestionStore.applySearch(undefined);
	},
});
