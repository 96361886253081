import * as querystring from 'querystring';
import { Url } from '../../../../navigation';

export const maybeNavigateToFlight = (
	query: querystring.ParsedUrlQueryInput,
	navigate: (url: Url) => void,
) => {
	if (window.location.pathname !== '/flights/') {
		navigate({ pathname: '/flights/', query });
	}
};
