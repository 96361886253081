import React from 'react';

interface IProps {
	query: string;
	value: string;
}

export const SearchAutoSuggestItem = ({ query, value }: IProps) => {
	const lowerCaseQuery = query.toLocaleLowerCase();
	const index = value.toLocaleLowerCase().indexOf(lowerCaseQuery);

	const before = value.substring(0, index);
	const match = value.substring(index, index + query.length);
	const after = value.substring(index + query.length);

	return (
		<div>
			{before}
			<strong>{match}</strong>
			{after}
		</div>
	);
};
