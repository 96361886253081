import { Url } from '../../../../navigation';
import { IEnrichedFlight } from '../types/IEnrichedFlight';

export const getFlightDetailsRoute = ({
	stepAirport,
	destinationAirport,
	scheduledTimestamp,
	originAirport,
	arrival,
	mainFlightNumber,
}: IEnrichedFlight): Url => ({
	pathname: '/flight',
	query: {
		scheduledTimestamp,
		mainFlightNumber,
		originAirport,
		stepAirport,
		destinationAirport,
		arrival,
	},
});
