import React from 'react';
import styled from '@emotion/styled';
import Link from 'next/link';
import { ChevronRightIcon, ITheme, UnstyledLi, themed } from '@m2/blueprint-ui';
import SignUpAccountBenefits from '../../../auth/sign-up/ui/SignUpAccountBenefits';
import { CustomPageId } from '../../../contentful/routes';
import { useTranslation } from '../../../i18n/translate/useTranslation';

export const AccountMenuItemLoggedOut = () => {
	const { t } = useTranslation();
	return (
		<>
			<UnstyledLi>
				<Link href={`/${CustomPageId.SignIn}`} passHref>
					<StyledAccountMenuSubMenuItem className="with-border">
						{t('parking_bookings_details_login_sign_in')}
						<ChevronRightIcon variation="brand" />
					</StyledAccountMenuSubMenuItem>
				</Link>
			</UnstyledLi>
			<UnstyledLi>
				<Link href={`/${CustomPageId.SignUp}`} passHref>
					<StyledAccountMenuSubMenuItem>
						{t('account_menu_create_account')}{' '}
						<ChevronRightIcon variation="brand" />
					</StyledAccountMenuSubMenuItem>
				</Link>
			</UnstyledLi>
			<UnstyledLi>
				<StyledAccountMenuAccountBenefits>
					<SignUpAccountBenefits />
				</StyledAccountMenuAccountBenefits>
			</UnstyledLi>
		</>
	);
};

export const StyledAccountMenuSubMenuItem = styled.a<{}, ITheme>`
	padding: 12px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid transparent;
	-webkit-appearance: none;
	background-color: unset;
	color: ${themed(({ color }) => color.text.default)};
	width: 100%;

	&:hover {
		text-decoration: none;
		color: ${themed(({ color }) => color.brand)};
	}

	&.with-border {
		border-bottom: 1px solid ${themed(({ color }) => color.border.default)};
	}
`;

const StyledAccountMenuAccountBenefits = styled.div<{}, ITheme>`
	pointer-events: none;
	padding: 0 16px;
	font-size: 14px;
	--paragraph-color: ${themed(({ color }) => color.text.default)};
	--paragraph-icon-color: ${themed(({ color }) => color.highlight)};
	--paragraph-margin: 8px;
`;
