import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
	Button,
	ChevronLeftIcon,
	ChevronRightIcon,
	Responsive,
} from '@m2/blueprint-ui';
import { ICarouselItemEntry, ICarouselSectionEntry } from '../../../contentful';

import { CarouselItem, CarouselPlaceholderItem } from './CarouselItem';
import { HighlightSection } from '../sections/components/HighlightSection';
import 'scroll-behavior-polyfill';
import { getListedEntryKey } from '../../../utils';
import { getScrollPositionForIndex } from './utils/getScrollPositionForIndex';
import { getScrollPercentage } from './utils/getScrollPercentage';
import { clamp } from '../../../utils/clamp';
import { getIndexForScrollPosition } from './utils/getIndexForScrollPosition';
import { useItemClickHandler } from './utils/useClickHandler';
import { CustomHighlightSectionDesktop } from '../highlight/Highlight';

export const CarouselContainer = ({
	section,
}: {
	section: ICarouselSectionEntry;
}) => {
	const { items } = section.fields;
	const [indexIsHighlighted, setIndexIsHighlighted] = useState<number>(1);
	const [canScrollLeft, setCanScrollLeft] = useState<boolean>(true);
	const [canScrollRight, setCanScrollRight] = useState<boolean>(true);
	const handleClickItem = useItemClickHandler();

	const containerRef = useRef<HTMLDivElement>(null);

	const scrollFunction = useCallback(() => {
		const container = containerRef.current;

		if (!container) {
			return;
		}

		const percentage = getScrollPercentage(container);

		setCanScrollLeft(percentage > 0);
		setCanScrollRight(percentage < 1);
	}, [setIndexIsHighlighted, items]);

	useEffect(() => {
		const container = containerRef.current;

		if (container) {
			container.addEventListener('scroll', scrollFunction);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', scrollFunction);
			}
		};
	}, [scrollFunction, containerRef]);

	const handleMouseEnter = (index: number) => {
		setIndexIsHighlighted(index);
	};

	const scrollToIndex = (index: number) => {
		const container = containerRef.current;

		if (!container) return;

		const scrollPositionX = getScrollPositionForIndex(
			container,
			index,
			items.length,
		);

		container.scrollTo(scrollPositionX, 0);
	};

	// Set initial scroll position
	useEffect(() => {
		scrollToIndex(indexIsHighlighted);
	}, []);

	function setScrollPosition(offset: number) {
		const container = containerRef.current;

		if (!container) {
			return;
		}

		const index = getIndexForScrollPosition(container, items.length);
		const nextIndex = clamp(index + offset, 0, items.length - 1);

		if (index !== nextIndex) {
			scrollToIndex(nextIndex);
		}
	}

	const handleClickLeft = () => {
		setScrollPosition(-2);
	};

	const handleClickRight = () => {
		setScrollPosition(2);
	};

	return (
		<StyledCarouselContainer>
			<StyledItemsContainerDesktop>
				<StyledGradientLeft
					onClick={handleClickLeft}
					isVisible={canScrollLeft}
				/>
				<StyledButtonLeft isVisible={canScrollLeft}>
					<Button
						onClick={handleClickLeft}
						disabled={!canScrollLeft}
						aria-label="scroll left"
					>
						<ChevronLeftIcon variation="inverted" />
					</Button>
				</StyledButtonLeft>

				<ItemsContainer ref={containerRef} role="group" tabIndex={0}>
					{items.map((item: ICarouselItemEntry, index) => (
						<CarouselItem
							key={getListedEntryKey(item, index)}
							imageSrc={item.fields.image.fields.file.url}
							title={item.fields.title}
							iconSrc={item.fields.icon?.fields.file.url}
							onClick={() => {
								handleClickItem(item);
							}}
							onMouseEnter={() => handleMouseEnter(index)}
							isHighlighted={indexIsHighlighted === index}
						/>
					))}
					<CarouselPlaceholderItem />
				</ItemsContainer>

				<StyledGradientRight
					onClick={handleClickRight}
					isVisible={canScrollRight}
				/>
				<StyledButtonRight isVisible={canScrollRight}>
					<Button
						onClick={handleClickRight}
						disabled={!canScrollRight}
						aria-label="scroll right"
					>
						<ChevronRightIcon variation="inverted" />
					</Button>
				</StyledButtonRight>
			</StyledItemsContainerDesktop>
			<CustomHighlightSection
				section={section.fields.items[indexIsHighlighted].fields.content}
			/>
		</StyledCarouselContainer>
	);
};

const CustomHighlightSection = styled(HighlightSection)`
	margin-left: 0;
	margin-right: 0;

	${CustomHighlightSectionDesktop} {
		margin-left: 0;
	}
`;

export const ItemsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	position: relative;
	width: 100vw;
	max-width: 1360px;
	overflow: hidden;
	flex-grow: 0;
	height: 440px;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;

	-ms-overflow-style: none;
	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}
`;

export const StyledButton = styled.div<{ isVisible: boolean }>`
	button > svg {
		margin-left: 0;
		transform: translateY(1px);
	}
	z-index: 6;
	position: absolute;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	transition: opacity 0.15s ease-in-out;

	button {
		display: flex;
		align-items: center;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const StyledButtonLeft = styled(StyledButton)`
	left: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		transform: translateX(10px);
	}
`;

export const StyledButtonRight = styled(StyledButton)`
	right: 0;

	@media (max-width: 1440px) {
		right: 74px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		right: 55px;
	}
`;

const StyledGradient = styled.div<{
	isVisible?: boolean;
	disabled?: boolean;
}>`
	width: 120px;
	top: 0;
	bottom: 0;
	z-index: 5;
	position: absolute;
	transition: opacity 0.15s ease-in-out;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'default')};
`;

export const StyledGradientLeft = styled(StyledGradient)`
	background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
	left: 0;
`;

export const StyledGradientRight = styled(StyledGradient)`
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
	right: 0;
`;

export const StyledItemsContainerDesktop = styled.div`
	display: flex;
	align-items: center;
	padding-top: 20px;

	position: relative;
	left: -40px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const StyledCarouselContainer = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 0;
`;
