import React, { PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import {
	AccordionContent,
	AccordionTitle,
	ITheme,
	Markdown,
	StyledAccordionTitle,
	themed,
	useControlledState,
} from '@m2/blueprint-ui';

export interface IAccordionItemProps {
	title: string | ReactNode;
	subtitle?: ReactNode;
	body?: string;
	// used to control the state of the accordion
	setIsExpanded?: (val: boolean) => void;
	isExpanded?: boolean;
	className?: string;
	icon?: ReactNode;
	withTitleHighlight?: boolean;
	reverseIcon?: boolean;
}

export const StyledAccordionItem = styled.div<{}, ITheme>`
	border-bottom: ${themed(({ accordion }) => accordion.border)};

	${StyledAccordionTitle} {
		padding: ${themed(({ accordion }) => accordion.title.padding)};
		font-weight: bold;
	}
`;

export const AccordionItem = ({
	title,
	subtitle,
	body,
	setIsExpanded: setIsExpandedProp,
	isExpanded: isExpandedProp,
	icon,
	children,
	className,
	reverseIcon = false,
	withTitleHighlight = true,
}: PropsWithChildren<IAccordionItemProps>) => {
	const [isExpanded, setIsExpanded] = useControlledState(
		isExpandedProp ?? false,
		isExpandedProp,
		setIsExpandedProp,
	);

	if (!children && !body) {
		console.warn('Please provide children or a body to the AccordionItem');
		return null;
	}

	return (
		<StyledAccordionItem className={className}>
			<AccordionTitle
				onClick={() => setIsExpanded(!isExpanded)}
				isActive={isExpanded && withTitleHighlight}
				reverseIcon={reverseIcon}
				title={title}
				icon={icon}
			/>
			{subtitle ? <div>{subtitle}</div> : null}
			<AccordionContent isVisible={isExpanded}>
				{children || <Markdown source={body as string} />}
			</AccordionContent>
		</StyledAccordionItem>
	);
};
