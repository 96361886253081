import React, { MouseEvent } from 'react';
import Downshift, { DownshiftState, StateChangeOptions } from 'downshift';
import {
	IDropdownItem,
	AutoSuggestInput,
	IAutoSuggestInputProps,
	SearchInputButtonWrap,
} from '@m2/blueprint-ui';

interface IAutoSuggestInterface extends IAutoSuggestInputProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onSelectItem: (selected: IDropdownItem | null) => void;
	onClickSearch: (e: MouseEvent) => void;
	disabled?: boolean;
}

export const AutoSuggest = ({
	onSelectItem,
	onClickSearch,
	disabled,
	...rest
}: IAutoSuggestInterface) => (
	<SearchInputButtonWrap disabled={disabled} onClickSearch={onClickSearch}>
		<AutoSuggestInput
			{...rest}
			onSelect={onSelectItem}
			stateReducer={stateReducer}
			disabled={disabled}
		/>
	</SearchInputButtonWrap>
);

function stateReducer(
	state: DownshiftState<IDropdownItem>,
	changes: StateChangeOptions<IDropdownItem>,
) {
	// This prevents the input value to be reset on
	// outer click or loss of focus.
	switch (changes.type) {
		case Downshift.stateChangeTypes.mouseUp:
		case Downshift.stateChangeTypes.blurInput:
		case Downshift.stateChangeTypes.touchEnd:
			return {
				...changes,
				inputValue: state.inputValue,
			};
		default:
			return changes;
	}
}
