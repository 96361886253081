import React from 'react';
import styled from '@emotion/styled';
import { IUrlLinkItem, Link, Responsive, ITheme } from '@m2/blueprint-ui';
import { Color } from '../../../styles/Color';
import { getLinkPropsForUrl } from '../../../navigation';

const FULL_YEAR = new Date().getFullYear();

const FooterFinePrintWrap = styled.div<{}, ITheme>`
	display: flex;
	justify-content: flex-start;

	margin-top: 40px;

	strong {
		min-width: 388px;
		margin-bottom: 10px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		strong {
			min-width: 100px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
`;

const FooterFinePrintLinks = styled.div<{}, ITheme>`
	font-size: 16px;
	margin-right: 20px;

	a {
		color: ${Color.PrimaryDarkGray100};
		margin-left: 20px;
	}

	${Link} {
		text-decoration: none;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		a {
			display: flex;
			margin-right: 0px;
			margin-left: 0px;
			margin-bottom: 10px;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		width: 100%;

		a {
			color: ${Color.PrimaryDarkGray100};
		}
	}
`;

export interface IFooterFinePrintProps {
	links?: IUrlLinkItem[];
}

export const FooterFinePrint = ({ links }: IFooterFinePrintProps) => (
	<FooterFinePrintWrap>
		<strong>{`© ${FULL_YEAR} Dallas Fort Worth International Airport`}</strong>
		<FooterFinePrintLinks>
			{links?.map((link) => (
				<Link
					hideExternalIndicator
					{...getLinkPropsForUrl(link.url)}
					aria-label={`Visit ${link.title} page (opens in new window)`}
				>
					{link.title}
				</Link>
			))}
		</FooterFinePrintLinks>
	</FooterFinePrintWrap>
);
