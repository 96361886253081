import { useEffect, useState } from 'react';
import { ILocalWeather } from './ILocalWeather';
import { useFlightStores } from '../../../flights/list/domain/stores/useFlightStores';

export function useLocalWeather() {
	const [localWeather, setLocaleWeather] = useState<ILocalWeather | undefined>(
		undefined,
	);

	const { weatherStore } = useFlightStores();

	async function loadLocalWeather() {
		await weatherStore.load({ iata: 'dfw' });

		const weather = weatherStore.weather?.current_weather;

		if (!weather || !weather.temperatureImperial) {
			return;
		}

		setLocaleWeather({
			description: weather.phrase ?? undefined,
			icon: weather.icon ?? undefined,
			temperatureImperial: weather.temperatureImperial,
		});
	}

	useEffect(() => {
		loadLocalWeather();
	}, []);

	return { localWeather };
}
