import React, { useEffect } from 'react';
import { removeUndefined } from '@m2/blueprint-utils';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { observer } from 'mobx-react';
import { IAssetEntry, IGenericContentPageEntry } from '../contentful/types';
import { defaultLocale } from '../i18n/Locale';
import { contentful } from '../contentful/client';
import { CustomPageId } from '../contentful/routes';
import { PageLayout } from '../components/common/wrappers/PageLayout';
import { StyledSectionsWrapper } from '../components/common/wrappers/StyledSectionsWrapper';
import { SolidContentWrap } from '../components/common/wrappers/SolidContentWrap';
import { SearchBar } from '../components/home/search/SearchBar';
import Custom404 from './404';
import { useLocalWeather } from '../components/common/weather/useLocalWeather';
import { useFlightStores } from '../flights/list/domain/stores/useFlightStores';
import { createSearchBarViewModel } from '../components/home/search/SearchBarViewModel';
import { useNavigate } from '../navigation';
import { createGetFlightDetailUrl } from '../flights/search/domain/use-cases/getFlightDetailUrl';
import { createFlightSuggestionStore } from '../flights/shared/domain/stores/createFlightSuggestionStore';
import { useCommonData } from '../common-data/useCommonData';
import { SectionList } from '../components/common/sections/SectionList';

export enum HomeSearchType {
	Flights = 'Flights',
	Parking = 'Parking',
	ShopDineServices = 'Shop/Dine/Services',
}
interface IHomePageProps {
	page: IGenericContentPageEntry | null;
	video?: IAssetEntry;
}

export async function getStaticProps() {
	const page = await contentful()
		.page()
		.getByPageId(CustomPageId.Home, defaultLocale.code);

	const video = await contentful().asset().getById('6kGNDJuPm5DIuTHiBm9dCc'); // NOTE: use asset ID for homepage header video

	return {
		props: removeUndefined({
			page,
			video,
		}),
	};
}

const Page = observer(({ page, video }: IHomePageProps) => {
	const { airlines, airports } = useCommonData();
	const { airlineStore, airportStore, flightListStore, flightStore } =
		useFlightStores();
	const { localWeather } = useLocalWeather();
	const navigate = useNavigate();

	useEffect(() => {
		airlineStore.setAirlines(airlines);
		airportStore.setAirports(airports);
	}, [airlineStore, airportStore]);

	const flightSuggestionStore = createFlightSuggestionStore({
		airlines,
		airports,
		flightListStore,
		flightStore,
		minLength: 2,
	});

	useEffect(() => {
		flightListStore.load();

		return () => {
			flightListStore.cancelCurrentRequest();
		};
	}, [flightListStore]);

	const getFlightDetailUrl = createGetFlightDetailUrl(flightListStore);

	if (!page) {
		return <Custom404 />;
	}

	return (
		<PageLayout page={page} video={video} localWeather={localWeather}>
			<ContentWrap>
				<StyledSolidContentWrap>
					<SearchBar
						viewModel={createSearchBarViewModel({
							flightSuggestionStore,
							navigate,
							getFlightDetailUrl,
						})}
					/>
				</StyledSolidContentWrap>
				<StyledSectionsWrapper>
					<SectionList sections={page.fields.sections} />
				</StyledSectionsWrapper>
			</ContentWrap>
		</PageLayout>
	);
});

export default Page;

export const ContentWrap = styled.div`
	position: relative;
	top: -80px;
`;

export const StyledSolidContentWrap = styled(SolidContentWrap)`
	margin-top: 0px;
	margin-bottom: 0px;
	padding-bottom: 0px;
	background-color: transparent;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-left: 0px;
		padding-right: 0px;
		margin-left: 16px;
		margin-right: 16px;
	}
`;
