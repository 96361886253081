import React from 'react';
import { getValueForItem, IDropdownItem } from '@m2/blueprint-ui';
import { observer } from 'mobx-react';
import { AutoSuggest } from '../../../components/common/auto-suggest/AutoSuggest';
import { SearchAutoSuggestItem } from '../../../components/home/search/SearchAutoSuggestItem';
import { IFlightSearchSuggestion } from '../domain/types/IFlightSearchSuggestion';
import { IFlightAutoSuggestViewModel } from './FlightAutoSuggestViewModel';
import { useTranslation } from '../../../i18n/translate/useTranslation';

interface IFlightAutoSuggestProps {
	viewModel: IFlightAutoSuggestViewModel;
}

export const FlightAutoSuggest = observer(
	({ viewModel }: IFlightAutoSuggestProps) => {
		const { isLoading, suggestionList, onInputValueChange, input, onClear } =
			viewModel;

		const itemToString = (item: IFlightSearchSuggestion | null) =>
			item ? item.stringValue : '';

		const renderItemDisplayValue = (item: IDropdownItem | null) => {
			if (item === null) {
				return '';
			}

			const value = getValueForItem(item);

			return <SearchAutoSuggestItem query={input} value={value} />;
		};

		const { t } = useTranslation();

		return (
			<AutoSuggest
				onInputValueChange={onInputValueChange}
				placeholder={t('homepage_flights_search_placeholder')}
				initialInputValue={input}
				itemToString={itemToString}
				itemToDisplayValue={renderItemDisplayValue}
				disabled={isLoading}
				onSelectItem={viewModel.onSelectItem}
				onClickSearch={viewModel.onClickSearch}
				items={suggestionList}
				onKeyUp={viewModel.onKeyUp}
				onClearSearch={onClear}
			/>
		);
	},
);
