import styled from '@emotion/styled';
import { ContentWrap, Responsive, themed, ITheme } from '@m2/blueprint-ui';

export const SOLID_CONTENT_WRAP_PADDING_DESKTOP = '30px';
export const SOLID_CONTENT_WRAP_PADDING_MOBILE_XL = '16px';
export const SOLID_CONTENT_WRAP_PADDING_MOBILE = '10px';

export const SolidContentWrap = styled(ContentWrap)<{}, ITheme>`
	padding: ${SOLID_CONTENT_WRAP_PADDING_DESKTOP};
	margin-top: 40px;
	margin-bottom: 40px;
	background: ${themed(({ color }) => color.background.default)};
	max-width: 1200px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${SOLID_CONTENT_WRAP_PADDING_MOBILE_XL};
		margin: 24px 16px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobile')}) {
		padding: ${SOLID_CONTENT_WRAP_PADDING_MOBILE};
		margin: 10px 8px;
	}
`;
