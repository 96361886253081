import React, { ReactNode, useState } from 'react';
import styled from '@emotion/styled';
import { IAssetEntry, IGenericContentPageEntry } from '../../../contentful';
import { Footer } from '../footer';
import { PageHeader } from '../header/PageHeader';
import { PageMetaData } from '../meta-data/PageMetaData';
import { ILocalWeather } from '../weather/ILocalWeather';
import PageAlerts from '../alerts/PageAlerts';

interface IPageLayout {
	children?: ReactNode;
	page?: IGenericContentPageEntry;
	video?: IAssetEntry;
	localWeather?: ILocalWeather;
	hasPageTitle?: boolean;
	isModern?: boolean;
	widget?: ReactNode;
	className?: string;
	showFooter?: boolean;
	showHeader?: boolean;
	showSecureWebpage?: boolean;
	headerContent?: ReactNode;
	pageTitle?: string;
	backLink?: string;
	backLinkText?: string;
}

export const PageLayout = ({
	children,
	page,
	video,
	localWeather,
	hasPageTitle,
	isModern,
	widget,
	className,
	showSecureWebpage,
	showHeader = true,
	showFooter = true,
	headerContent,
	pageTitle,
	backLink,
	backLinkText,
}: IPageLayout) => {
	const [searchActive, setSearchActive] = useState(false);

	return (
		<>
			{page && (
				<PageMetaData
					title={page.fields.seoTitle}
					description={page.fields.seoDescription}
				/>
			)}
			{!searchActive && <PageAlerts />}
			<ContentWrapper className={className}>
				{showHeader && (
					<PageHeader
						page={page}
						video={video}
						localWeather={localWeather}
						hasPageTitle={hasPageTitle}
						isModern={isModern}
						setSearchActive={setSearchActive}
						widget={widget}
						searchActive={searchActive}
						showSecureWebpage={showSecureWebpage}
						content={headerContent}
						title={pageTitle}
						backLink={backLink}
						backLinkText={backLinkText}
					/>
				)}
				<main id="main">{children}</main>
				{showFooter && <Footer />}
			</ContentWrapper>
		</>
	);
};

const ContentWrapper = styled.div`
	position: relative;
`;
