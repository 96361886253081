import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import {
	PropsOf,
	themed,
	ITheme,
	Responsive,
	CancelIcon,
} from '@m2/blueprint-ui';
import { observer } from 'mobx-react';
import WarningIcon from '../../../assets/icon-warning.svg';
import { useNewsAlertStore } from './useNewsAlertStore';

type IDialogOverlayProps = PropsOf<typeof DialogOverlay>;

export const StyledMobileHeadingIcons = styled.div`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: flex;
		justify-content: space-between;

		* {
			width: 40px;
			height: 40px;
			margin-bottom: 24px;
		}

		svg:last-of-type {
			cursor: pointer;
		}
	}
`;

export const StyledIcon = styled.image`
	svg {
		width: 40px;
		height: 40px;
		margin-right: 40px;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		svg {
			display: none;
		}
	}
`;

export const StyledDialogOverlay = styled(DialogOverlay)`
	z-index: 2000;
`;

export const StyledDismiss = styled.span`
	color: white;
	font-weight: bold;
	cursor: pointer;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

export const StyledChildrenWrapper = styled.div`
	p {
		color: white;
	}
`;

export const StyledHeading = styled.div`
	display: flex;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
	}
`;

export const StyledTitle = styled.h1`
	color: white;
	margin-right: auto;
	font-size: 24px;
	font-weight: normal;
	margin-bottom: 32px;
`;

export const StyledColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const StyledDialogContent = styled(DialogContent)<{}, ITheme>`
	div:first-of-type {
		width: auto;
	}
	&[data-reach-dialog-content] {
		width: 90vw;
		max-width: ${themed(({ layout }) => layout.main.maxWidth)};
		padding: 48px 80px;
		margin: 10vh auto;
		background: #b40000;
		box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		display: flex;
		flex-direction: row;

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			width: 90%;
			padding: 48px 40px;
		}
	}
`;

export const StyledCancelIcon = styled.div``;

interface IDialogProps extends IDialogOverlayProps {
	body: string;
	title: string;
	dismissLabel: string;
	id: string;
}

export const CrisisNewsAlert = observer(
	({ body, title, dismissLabel, id, ...rest }: IDialogProps) => {
		const { newsAlertStore } = useNewsAlertStore();

		useEffect(() => {
			newsAlertStore.init();
		}, []);

		if (!newsAlertStore.getShouldShow(id)) {
			return null;
		}

		return (
			<StyledDialogOverlay
				isOpen
				onDismiss={() => newsAlertStore.dismiss(id)}
				{...rest}
			>
				<StyledDialogContent aria-label="arialabel">
					<StyledColumnWrapper>
						<StyledIcon>
							<WarningIcon aria-hidden />
						</StyledIcon>
					</StyledColumnWrapper>
					<StyledColumnWrapper>
						<StyledHeading>
							<StyledMobileHeadingIcons>
								<WarningIcon aria-hidden />
								<StyledCancelIcon onClick={() => newsAlertStore.dismiss(id)}>
									<CancelIcon variation="inverted" />
								</StyledCancelIcon>
							</StyledMobileHeadingIcons>
							<StyledTitle>{title}</StyledTitle>
							<StyledDismiss onClick={() => newsAlertStore.dismiss(id)}>
								{dismissLabel}
							</StyledDismiss>
						</StyledHeading>
						<StyledChildrenWrapper>
							<p>{body}</p>
						</StyledChildrenWrapper>
					</StyledColumnWrapper>
				</StyledDialogContent>
			</StyledDialogOverlay>
		);
	},
);
