import { IFlightListStore } from '../../../list/domain/stores/FlightListStore';
import { getFlightDetailsRoute } from '../../../shared/domain/util/getFlightDetailsRoute';

export type GetFlightDetailUrl = ReturnType<typeof createGetFlightDetailUrl>;

export function createGetFlightDetailUrl(flightListStore: IFlightListStore) {
	return function getFlightDetailUrl(value: string) {
		const filteredFlights = flightListStore.getFlightsByNumber(value);
		if (filteredFlights.length === 1) {
			return getFlightDetailsRoute(filteredFlights[0]);
		}
		return undefined;
	};
}
